<template>
  <transition>
    <div class="cardBillDetail" v-loading="fullscreenLoading">
      <span class="card-icon">
         <el-button type="primary"  class="themed-button" @click="refresh(true)">获取实时状态</el-button>
          <el-button type="primary"  class="themed-button" @click="clearHistory">清除缓存</el-button>
      </span>
    
      <div class="group">
        <h1 class="title">基础信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
             <td>ICCID</td>
             <td>{{ data.iccidMark }}</td>
              <td>接入号</td>
              <td>{{ data.phone }}</td>
               <td>短号</td>
              <td>{{shortIccid}}</td>
               <td>套餐类型</td>
                <td>{{ data.mealTypeStr }}</td>
             </tr>
             <tr>
              <td>过期时间</td>
              <td>{{ data.expireDate }}</td>
               <td>运营商</td>
                <td>{{ data.accountName }}</td>
                <td>网卡状态</td>
                <td>{{data.statusString}}</td>
                <td>代理商</td>
                 <td>
                  <span v-if="data.agentName">{{ data.agentName }}</span>
                  <span v-else>未分配</span>
              </td>
             </tr>
             <tr>
              <td>导入时间</td>
              <td>{{importTime}}</td>
               <td>卡类型</td>
                 <td>
                <span v-if="data.type == 1">包月</span>
                <span v-else>包年</span>
              </td>
              <td>绑定手机</td>
               <td>
                <span v-if="res.phonebind">{{ res.phonebind }}</span>
                <span v-else>未绑定</span>
              </td>
               <td>实名状态</td>
                 <td>
                  <span v-if="isReal == 1">已实名</span>
                  <span v-else-if="isReal == 2">未实名</span>
                  <span v-else>—未知状态—</span>
                  <el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="_isRealName" style="margin-left:3px"></el-button>
              </td>
             </tr>
             <tr>
               <td>断网状态</td>
              <td style="display: table-cell; vertical-align: middle">
                <span v-html="getCutStatus"></span>
                <el-button
                  icon="el-icon-refresh-right"
                  type="primary"
                  class="themed-button"
                  @click="isOperatorFunciton"
                  style="margin-left: 3px"
                ></el-button>
              </td>
                <td>提前停机</td>
              <td>
                <span v-if="stopAheadDays!=0">{{stopAheadDays+"天"}}</span>
               
                <span v-else>—</span>
                <el-button
                  icon="el-icon-refresh-right"
                  type="primary"
                  class="themed-button"
                  @click="getStopAheadDaysInfo"
                  style="margin-left: 3px"
                ></el-button>
                <!-- <i class="el-icon-refresh-right" @click="_isRealName"></i> -->
              </td>
             </tr>
           </table>
        </div>
      </div>
       <div class="group">
        <h1 class="title">流量信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
             <td>套餐总流量</td>
             <td>{{ data.flowSize }}MB</td>
              <td>已使用流量</td>
               <td>{{ parseFloat(data.totalBytesCnt).toFixed(2)}}MB</td>
               <td>剩余流量</td>
                <td>{{ parseFloat(data.flowSize - data.totalBytesCnt).toFixed(2)  }}MB</td>
                <td>基础套餐</td>
                  <td><span v-if="data.setMealName">{{ data.setMealName }}</span> <span v-else>-</span></td>
                 <!-- <td v-else>-</td> -->
             </tr>
             <tr>
             <td >总语音(分钟)</td>
              <td>{{data.voiceSize}}分钟</td>
              <td >已使用语音(分钟)</td>
              <td>{{voiceUsed}}</td>
              <td >剩余语音(分钟)</td>
              <td>{{voiceRemaining}}</td>
              <td></td>
              <td></td>
             </tr>
            
           </table>
        </div>
      </div>
       <div class="group">
        <h1 class="title">当前套餐</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
             <!-- <td v-html="currentMeal"></td> -->
              <td>
                <p>当前生效套餐：</p>
                <span v-html="currentMeal"></span>
                <p>待生效套餐：</p>
                <span v-html="willMeal"></span>
              </td>
             </tr>
           </table>
        </div>
      </div>
      <div class="group">
        <h1 class="title">常规操作</h1>
        <div>
          <ul class="clearfix">
            <li @click="mealRecharge">
              <img src="./1.png" alt="" />
              <p>套餐充值</p>
            </li>
            <!-- <li @click="activate">
              <img src="./2.png" alt="" />
              <p>激活</p>
            </li> -->
            <li @click="znzd">
              <img src="./2.png" alt="" />
              <p>智能诊断</p>
            </li>
            <li @click="whiteNubmerSet">
              <img src="./7.png" alt="" />
              <p>白名单设置</p>
            </li>
            <li @click="cardRecovery">
              <img src="./3.png" alt="" />
              <p>保号复机</p>
            </li>
            <li @click="cardStop">
              <img src="./4.png" alt="" />
              <p>保号停机</p>
            </li>
            <li v-if="imEffectRight" @click="advanceLife">
              <img src="./4.png" alt="" />
              <p>套餐提前生效</p>
            </li>
             <li @click="flowDetail">
              <img src="./4.png" alt="" />
              <p>流量详单</p>
            </li>
            <!-- <li @click="limitStop">
              <img src="./4.png" alt="" />
              <p>设置达量断网</p>
            </li>
            <li @click="limitStopRecovery">
              <img src="./4.png" alt="" />
              <p>达量断网复机</p>
            </li>
           
            <li @click="updateExpire">
              <img src="./5.png" alt="" />
              <p>更改过期时间</p>
            </li>
            <li @click="setThreshold">
              <img src="./6.png" alt="" />
              <p>更新阈值</p>
            </li>
            <li @click="toNewCard">
              <img src="./7.png" alt="" />
              <p>转新卡</p>
            </li>
            <li @click="deviceCardBound">
              <img src="./8.png" alt="" />
              <p>机卡绑定</p>
            </li>
            <li @click="stopRecoveryRecord">
              <img src="./9.png" alt="" />
              <p>停复机记录</p>
            </li>
            <li @click="orderRecord">
              <img src="./9.png" alt="" />
              <p>往期订单</p>
            </li> -->
            <!-- <li @click="singleLimit">
                        <img src="./7.png" alt="">
                        <p>单卡限速</p>
                    </li> -->
            <!-- <li @click="tamperRecovery">
              <img src="./7.png" alt="" />
              <p>窜卡复机</p>
            </li>
            <li @click="noLoopcard">
              <img src="./7.png" alt="" />
              <p>不轮询网卡</p>
            </li>
            <li @click="modifyFlow">
              <img src="./7.png" alt="" />
              <p>增减流量</p>
            </li>
            
            <li @click="delOnlyLoop">
              <img src="./7.png" alt="" />
              <p>删除仅轮询</p>
            </li> -->
               <li @click="returnToZero" v-if="currentRole==1&&agentTree==1">
              <img src="./7.png" alt="" />
              <p>退货清零</p>
            </li>
          </ul>
        </div>
      </div>

      <el-dialog title="套餐充值" :visible.sync="dialogAddPackage">
        <add-package
          :addPackageData="addPackageData"
          :curInfor="data"
          @close="dialogAddPackage = false"
        ></add-package>
      </el-dialog>
      <el-dialog title="设置达量" :visible.sync="dialogLimitStop">
        <limit-stop
          :iccidMark="iccidMark"
          @close="dialogLimitStop = false"
        ></limit-stop>
      </el-dialog>
      <el-dialog title="流量详单" :visible.sync="dialogFlowDetail">
        <flow-detail :iccidMark="iccidMark" :operType="data.category"></flow-detail>
      </el-dialog>
      <el-dialog title="变更过期时间" :visible.sync="dialogUpdateExpire">
        <update-expire :cardId="cardId"></update-expire>
      </el-dialog>
      <el-dialog title="转新卡" :visible.sync="dialogToNewCard">
        <tonew-card
          :iccidMark="iccidMark"
          @close="dialogToNewCard = false"
        ></tonew-card>
      </el-dialog>
      <el-dialog title="增减流量" :visible.sync="dialogModifyFlow">
        <change-flow
          :curInfor="data"
          @close="dialogModifyFlow = false"
        ></change-flow>
      </el-dialog>
       <el-dialog title="白名单设置" :visible.sync="dialogWhiteVoice" v-if="dialogWhiteVoice">
        <white-voice :iccidMark="iccidMark" :phone="data.phone" @close="dialogWhiteVoice = false"  ></white-voice>
      </el-dialog>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
import AddPackage from "./cardBillDetail/addPackage"
import LimitStop from "./cardBillDetail/limitStop"
import FlowDetail from "./cardBillDetail/flowDetail"
import UpdateExpire from "./cardBillDetail/updateExpire"
import TonewCard from "./cardBillDetail/toNewCard"
import ChangeFlow from "./cardBillDetail/changeFlow"
import WhiteVoice from "./cardBillDetail/whiteVoice.vue"

import {
  findCardInfo, getPackageAll,activate_,cardRecovery_,cardStop_,limitStopRecovery_,setThreshold_,deviceCardBound_,tamperRecovery_,noLoopcard_,allowRealname_,openVoice_,delOnlyLoop_,intelliDiagnose,isRealName,mealAdvanceLife,getButtonResource,clearHistory,extraStatus,returnToZero,getStopAheadDays
} from "@/api/cardManagement/cardBillDetail.js";

import { messageBox, confirmBox } from "@/utils/common.js";
import loading from "@/utils/Loading.js";
import ToNewCard from "./cardBillDetail/toNewCard.vue";
import {mapGetters} from 'vuex'
import dictGroup from '@/mixins/dictGroup.js'

export default {
  name:'soloKawudailishang',
  mixins:[dictGroup],
  data() {
    return {
      imEffectRight:false,
      fullscreenLoading:false,
      res: {},
      data: {},
      accountEntity: [],
      cardLiftList: [],
      nextCardLifeList: [],
      tianDjbCardLifes: [],
      shortIccid:'',
      dialogAddPackage: false,
      addPackageData: {},

      dialogLimitStop: false,
      dialogFlowDetail: false,
      dialogUpdateExpire: false,
      dialogToNewCard: false,
      dialogModifyFlow:false,
      dialogWhiteVoice:false,
      isReal:0,
      znzd_ing: false,
      isLimitStop: "",
      isAloneLimitStop: "",
      ExtraMsg: "",
      StopThreshold: "",
      stopAheadDays:0,
    };
  },
  computed: {
    ...mapGetters(
      ['iccidOrPhone','status','type',"agentTree","currentRole"]
    ),
    voiceUsed(){
        let flowCnt_voice = parseInt(this.data.totalBytesCntVoice)
        if(flowCnt_voice && flowCnt_voice!=""){
            flowCnt_voice = parseFloat(flowCnt_voice).toFixed(0);
        }
        return flowCnt_voice+"分钟"
    },
    voiceRemaining(){
        return (this.data.voiceSize - parseInt(this.data.totalBytesCntVoice)).toFixed(0) + '分钟'
    },
    getCutStatus() {
      let statusStr = "-";
      console.log(this.ExtraMsg,1111 )
       console.log(this.ExtraMsg != "",88888 )
      if (this.ExtraMsg != "") {
        if (this.ExtraMsg == "不支持该接口") {
          statusStr = "不支持查询";
        } else {
          statusStr = "查询错误";
        }
      } else {
        console.log(99999)
        if (this.isLimitStop && this.isAloneLimitStop) {
          if (this.StopThreshold) {
            //  statusStr="断网值"+this.StopThreshold+",已达量断网,已单独断网"
            statusStr = `断网值${this.StopThreshold}M<br>
          已达量断网<br>
          已单独断网`;
          } else {
            statusStr = `断网值空<br>
                   已达量断网<br>
                   已单独断网`;
          }
        } else if (this.isLimitStop && !this.isAloneLimitStop) {
          if (this.StopThreshold) {
            statusStr = `断网值${this.StopThreshold}M<br>
           已达量断网<br>
           未单独断网`;
          } else {
            statusStr = `断网值空<br>
             已达量断网<br>
             未单独断网`;
          }
        } else if (!this.isLimitStop && this.isAloneLimitStop) {
          if (this.StopThreshold) {
            statusStr = `断网值${this.StopThreshold}M<br>
           未达量断网<br>
           已单独断网`;
          } else {
            statusStr = `断网值空<br>
             未达量断网<br>
             已单独断网`;
          }
        } else {
          if (
            this.isLimitStop.toString() == "false" &&
            this.isAloneLimitStop.toString() == "false"
          ) {
            console.log(100000)
            if (this.StopThreshold) {
              console.log(1111110000)
              statusStr = `断网值${this.StopThreshold}M<br>
                     未达量断网<br>
                     未单独断网`;
            } else {
              statusStr = `断网值空<br>
             未达量断网<br>
             未单独断网`;
            }
          }
        }
      }

      return statusStr;
    },

    importTime() {
      //导入时间
      let createDateTime = this.data.createDateTime + "";
      return (
        createDateTime.substr(0, 4) +
        "-" +
        createDateTime.substr(4, 2) +
        "-" +
        createDateTime.substr(6, 2) +
        " " +
        createDateTime.substr(8, 2) +
        ":" +
        createDateTime.substr(8, 2) +
        ":" +
        createDateTime.substr(10, 2) +
        ":" +
        createDateTime.substr(12, 2)
      );
    },
   
     willMeal() {
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix < compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      if (this.nextCardLifeList.length > 0) {
        //  msg+="待生效套餐<br>"
        this.nextCardLifeList.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }
      if (this.tianDjbCardLifes.length > 0) {
        this.tianDjbCardLifes.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " +
            "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }

      return msg;
    },
    currentMeal() {
      //当前套餐
      console.log(this.cardLiftList, this.nextCardLifeList);
      let msg = "";
      if (this.cardLiftList.length > 0) {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
        this.cardLiftList.forEach((item) => {
          let compareDate = "";
          let compareDate_unix = "";
          if (item.startDate) {
            compareDate = item.startDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {
            let effictDate = "";
            if (item.startDate || item.expireDate) {
              effictDate =
                item.startDate.substring(0, 10) +
                "至" +
                item.expireDate.substring(0, 10);
            } else {
              effictDate = "-";
            }
            msg +=
              item.mealName +
              "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> " +
              effictDate +
              "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> " +
              item.flowSize +
              "MB " +
              item.voiceSize +
              "分钟<br>";
          }
        });
      }

      return msg;
    },
    gparams() {
      let params = {};
      params.iccidOrPhone = this.data.iccidMark
      params.setMealId = this.data.setMealId
      params.isChild = this.data.isChild
      params.codeChild = this.data.codeChild
      params.cardType = this.data.category
      params.status = this.data.status
      params.type = this.data.type
      return params
    },
    iccidMark() {
      return this.data.iccidMark;
    },
    cardId() {
      return this.data.id;
    },
  },
  mounted() {
    this.fullscreenLoading = true
    this._findCardInfo(false);
    this.getImEffectRight()
  },
  methods: {
     getStopAheadDaysInfo(){
       getStopAheadDays({iccid:this.iccidMark}).then(res=>{
          console.log(res)
          this.stopAheadDays  = res.stopAheadDays
       })  
    },
    //退货清零
    returnToZero() {
      this.$confirm(
        "执行退货清零后，将清除该卡的所有数据，并且不可恢复，确定要执行吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let params = {};
          params.iccid = this.iccidMark;
          returnToZero(params)
            .then((res) => {
              this.$message({
                showClose: true,
                message: "退货清零成功",
                type: "success",
              });
            })
            .catch((error) => {
              this.$message({
                showClose: true,
                message: "退货清零失败",
                type: "warning",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退货清零操作",
          });
        });
    },
     isOperatorFunciton() {
      this.getExtraStatus();
    },
      getExtraStatus() {
      let params = this.$route.query;
      console.log(params);
      let param = {};
      if (!params.iccidOrPhone) {
        param.iccid = this.iccidOrPhone;
      } else {
        param.iccid = params.iccidOrPhone;
      }
      console.log(param);
      extraStatus(param).then((res) => {
        console.log(res);
        if (res.system_result_key == "0") {
          if (res.extra) {
            this.isLimitStop = res.extra.isLimitStop == "false" ? false : true;
            this.isAloneLimitStop =
              res.extra.isAloneLimitStop == "false" ? false : true;
            this.StopThreshold = res.extra.threshold;
            console.log(this.isLimitStop,this.isAloneLimitStop,888888)
          }
        } else {
          this.ExtraMsg = res.system_result_message_key;
          this.$message({
            showClose: true,
            message: res.system_result_message_key,
            type: "error",
          });
        }
      });
    },
     clearHistory(){
      clearHistory({iccid:this.iccidMark}).then(res=>{
           if (res.system_result_key == '0') {
             messageBox(this,"清除成功")
                   this._findCardInfo(true)       
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
      })
    },
    getImEffectRight(){  //是否显示立即生效
        getButtonResource().then(res=>{
          this.imEffectRight = res.im_button
        })
    },
    znzd(){ //智能诊断
         if(this.znzd_ing==true){
         messageBox(this,'正在诊断，请稍后。')
         return 
         }
         this.znzd_ing = true
        intelliDiagnose({iccidMark:this.iccidMark}).then((res)=>{
            messageBox(this,res.retMsg)
            this.znzd_ing = false
        })
    },

    _isRealName(){ //实名状态
      isRealName({iccidOrPhone:this.iccidMark}).then((res)=>{
        if (res.isRealName == false){
            this.isReal = 2
        }else if (res.isRealName == true){
            this.isReal = 1
        }
      })
    },

    delOnlyLoop() {//删除仅轮询
      this.fullscreenLoading = true
      delOnlyLoop_(this.gparams).then((res) => {
        this._handleResponse(res)
      }).catch(()=>{
        this.fullscreenLoading = false
      });
    },

    whiteNubmerSet() {//白名单设置
      // if(this.accountEntity.category==="7" && (this.accountEntity.secondCategory||"")==="2") {
      //     // var iccidOrPhone = $("#iccidOrPhone").val();
      //     // selections = [{"iccidMark": iccidOrPhone}]

      //     // parent.parent.addTab("whitenumber_list","白名单设置",'cardBusiness/white_number_list.html?1=1&callbackFunction=reloadPageData&targetFrame='+frameId+'&iccidMark='+iccidOrPhone,"activity")

      //     alert('跳转白名单设置 没看到现象 没做')
      // }
      if(!!this.accountEntity.hasVoice){
          // this.$router.push({
          //         path: '/historyRecord/voiceWhitelist',
          //         query: {
          //             iccid: this.iccidMark
          //         }
          // })
          this.dialogWhiteVoice = true
      } else {
          messageBox(this,"该卡不支持此操作！")
      }
    },
    modifyFlow() {  //增减流量
      this.dialogModifyFlow = true
    },
    noLoopcard() {  //不轮询网卡
      if (this.res.noLoop) {
        messageBox(this, "该卡已经是不轮询状态。");
        return
      }
      confirmBox(this, "添加成功后该卡将不执行停复机操作同时会出现流量，状态不更新等状态，请谨慎操作！",'操作提示',this._noLoopcard_)
      
    },
    tamperRecovery() { //窜卡复机
      this.fullscreenLoading = true
      tamperRecovery_(this.gparams).then((res) => {
        this._handleResponse(res)
      }).catch(()=>{
        this.fullscreenLoading = false
      })
    },
    singleLimit() { //单卡限速
    },
    orderRecord() {  //往期订单
      // this.$router.push({
      //   path: "/cardManagement/orderRecord",
      //   query: {
      //     iccidMark: this.iccidMark
      //   },
      // })
      this.$router.push({path:'/cardManagement/orderRecord/'+this.iccidMark}).catch(() => true);
    },
    stopRecoveryRecord() { ///停复机记录
      this.$router.push({
        path: "/cardManagement/tingfujiRecord",
        query: {
          iccidMark: this.iccidMark,
        },
      })
    },
    deviceCardBound() {
      //机卡绑定
      let params = Object.assign(this.gparams, {
        iccidMark: this.data.iccidMark,
      });
      deviceCardBound_(params).then((res) => {
        this._handleResponse(res);
      });
    },
    toNewCard() {
      //转新卡
      this.dialogToNewCard = true;
    },
    setThreshold() {
      //更新阈值
      this.fullscreenLoading = true
      setThreshold_(this.gparams).then((res) => {
        this._handleResponse(res);
      }).catch(()=>{
        this.fullscreenLoading = false
      })
    },
    updateExpire() {
      //更改过期时间
      this.dialogUpdateExpire = true;
    },
    flowDetail() {
      //流量详单
      this.dialogFlowDetail = true;
    },
    limitStopRecovery() {
      //达量断网复机
      this.fullscreenLoading = true
      limitStopRecovery_(this.gparams).then((res) => {
        this._handleResponse(res);
      }).catch(()=>{
        this.fullscreenLoading = false
      })
    },
    limitStop() {
      //达量断网
      //if (this.data.category === '1' || this.data.category === '2') {
      if (this.data.category === this._getDictValue("DX_",this.w_ACCOUNT_)) {
        this.dialogLimitStop = true;
      } else {
        messageBox(this, "该卡不支持此操作！");
      }
    },
    cardStop() {
      //保号停机
      this.fullscreenLoading = true
      cardStop_(this.gparams).then((res) => {
        this._handleResponse(res);
      }).catch(()=>{
        this.fullscreenLoading = false
      })
    },

    advanceLife(){ //套餐提前生效
      mealAdvanceLife({iccidMark:this.data.iccidMark}).then(res=>{
        this._handleResponse(res);
      })
    },

    cardRecovery() {
      //保号复机
      this.fullscreenLoading = true
      cardRecovery_(this.gparams).then((res) => {
        this._handleResponse(res);
      }).catch(()=>{
        this.fullscreenLoading = false
      })
    },
    activate() {
      //激活
      activate_(this.gparams).then((res) => {
        this._handleResponse(res);
      });
    },
     compareResult(expireDate){
      let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentDate = year + "/" + month + "/" + day;
        let currentDate_unix = Date.parse(currentDate);
         let compareDate = "";
          let compareDate_unix = "";
          if (expireDate) {
            compareDate = expireDate.substring(0, 10).replace(/\-/g, "/");
            compareDate_unix = Date.parse(compareDate);
          }
          if (currentDate_unix >= compareDate_unix) {//当前时间大于等于过期时间   没有有效套餐
            return  false
          }
          return true
    },
    mealRecharge() {
      //套餐充值
      if ((typeof this.res.buchongzhi) != "undefined" && this.res.buchongzhi == true) {
        messageBox(this, "该卡暂不支持充值。");
        return;
      }
      if (this.data.expireDate != "-" && this.compareResult(this.data.expireDate) ) {
        confirmBox(
          this,
          "该卡已有套餐，确认是否继续充值?",
          "操作提示",
          this._mealRecharge
        );
      }else{
        this._mealRecharge()
      }
    },
    refresh(type) {
      this.fullscreenLoading = true
      this._findCardInfo(type);
    },
    _noLoopcard_(){
      noLoopcard_(this.gparams).then((res) => {
        this._handleResponse(res);
      });
    },
    _findCardInfo(type) {
      let params = this.$route.query;
      let cache 
      if(!!type){
        cache = false//实时获取
      }else {
         cache = true//本地获取
      }
      if(!params.iccidOrPhone){
          params= {
            iccidOrPhone:this.iccidOrPhone
          }
      }
      params.status = 0
      params.type = 0
      params.cache = cache
      findCardInfo(params).then((res) => {
        this.fullscreenLoading = false
        this.res = res
        this.data = res.entity
        console.log(this.res.phonebind)
        this.accountEntity = res.accountEntity
        this.cardLiftList = res.cardLiftList
        this.nextCardLifeList = res.nextCardLifeList
        this.tianDjbCardLifes = res.tianDjbCardLifes
        this.shortIccid = res.shortIccid
      });
    },
    _mealRecharge() {
      loading.show();
      let params = {};
      params.iccidOrPhone = this.data.iccidMark;
      params.setMealId = this.data.setMealId;
      params.isChild = this.data.isChild;
      params.codeChild = this.data.codeChild;
      getPackageAll(params).then((res) => {
        this.dialogAddPackage = true;
        this.addPackageData = res;
      });
    },
    _handleResponse(res) {
      if (res.app_result_key == 1) {
        messageBox(this, res.app_result_message_key);
        return;
      }else{
        let msg= res.system_result_message_key
        if(!msg){
            msg = "操作成功"
        }
        messageBox(this,msg);
      }
      this._findCardInfo(true);
    },
  },
  components: {
    AddPackage,
    LimitStop,
    FlowDetail,
    UpdateExpire,
    TonewCard,
    ChangeFlow,
     WhiteVoice,
  },
};
</script>

<style>
ul {
  list-style: none;
}
.cardBillDetail {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
}
.cardBillDetail .card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(7, 17, 27, 0.5);
}
.cardBillDetail .card-icon i {
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
.cardBillDetail .group {
  padding: 25px;
  background: #fff;
}
/* .cardBillDetail .group table {
  border-left: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
} */
.cardBillDetail .group table td {
  height: 32px;
  line-height: 32px;
  /* border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7; */
  text-align: left;
}
.cardBillDetail .group .title {
  height: 32px;
  line-height: 32px;
}
.cardBillDetail ul > li {
  float: left;
  width: 125px;
  text-align: center;
  margin-right: 20px;
  margin-top: 10px;
}
</style>
